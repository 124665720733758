import React, { useState } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Steps,
  Row,
  Col,
  Select,
  Card,
  Divider,
  Modal,
  message
} from "antd";

import {
  QuestionCircleOutlined
} from '@ant-design/icons';

import { useHistory } from "react-router-dom";

import "./MainForm.scss";

const { Option } = Select;


const helpDocs = {
  fyf: "https://intercom.help/fix-your-funnel/en/articles/5589045-10dlc-registation-information",
  engage:
    "http://help.engagecrm.io/en/articles/5589097-10dlc-registration-information",
  yeti: "http://help.yetitext.com/en/articles/5589515-what-is-a2p-10dlc",
};

const StarterForm = props => {
  // NOTE: only doing snake case because that is what Twilio expects in the api calls
  const [formData, setData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [visible, setVisible] = useState(false);

  const [modalText, setModalText] = useState(
    `"By submitting this form, you consent to a one-time charge of $4.00 for the review process. Approval is not guaranteed and our support team will reach out if necessary. To avoid additional charges, kindly refrain from submitting multiple forms."`
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setModalText("Form is being submitted please wait to be redirected");

    setConfirmLoading(true);
    try {
      await sendToServer();
      setVisible(false);
      setConfirmLoading(false);
      history.replace(`/complete?platform=${props.platform}`);
    } catch (error) {
      setModalText(
        "There has been an error please contact support or try again. By submitting this form you agree to have your associated account charged $4.00 one time for the approval process to take place. This does not gaurantee carrier approval. If not approved, support will reach out. Please do not submit multiple forms to avoid excess charges to your account."
      );

      const errorMessage =
        error.message ?? "Oops Something went wrong. Please contact support.";
      message.error(errorMessage);
      setVisible(false);
      setConfirmLoading(false);
    }
  };
  const history = useHistory();

  const handleChange = (name, value) => {
    const data = { ...formData };
    data[name] = value;
    setData(data);
  };

  const combineMessageTypes = values => {
    const clone = { ...values };
    let campaignMessageSamples = [];
    if (clone.campaignMessageSample1 && clone.campaignMessageSample2) {
      campaignMessageSamples.push(
        clone.campaignMessageSample1,
        clone.campaignMessageSample2
      );
    } else {
      return;
    }
    clone.campaignMessageSamples = campaignMessageSamples;
    delete clone.campaignMessageSample1;
    delete clone.campaignMessageSample2;
    return clone;
  };

  const sendToServer = async values => {
    const resData = combineMessageTypes(values);
    // console.log(values, props);
    const { uuid, platform } = props;
    if (!uuid) {
      message.error("invalid url");
      throw new Error("invalid url");
    }
    let res = await axios.post(
      `https://hixazgllr5.execute-api.us-west-2.amazonaws.com/prod/starter-brand-registration/${platform}/${uuid}`,
      resData
    );
    const body = JSON.parse(res.data.body);

    if (res.data.statusCode == 422 && body.success !== true) {
      throw new Error(body.reason);
    }

    if (res.data.statusCode !== 200 || body.success !== true) {
      throw new Error();
    }
    return values;
  };

const onFinish = () => {
          showModal();     
    }


  const refreshStart = () => {
    history.go(0);
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="card-wrapper">
      <Card >
        <Form
          name="starter"
          onFinish={onFinish}
          className="form-wrapper"
          layout="vertical"
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span="8"></Col>
            <Col span="8" style={{ textAlign: "center" }}>
              <h1>10 DLC Campaign Registration</h1>
            </Col>
          </Row>

          <Divider></Divider>

          {/* Business Name */}

          <div>
            <Form.Item
              label="Legal Business Name"
              name="business_name"
              rules={[
                { required: true, message: "Please input your Business Name!" }
              ]}
            >
              <Input
                value={formData?.business_name}
                name="business_name"
                onChange={e => {
                  handleChange("business_name", e.target.value);
                }}
              />
            </Form.Item>

            {/* TODO implement address */
            /* Physical Address (Street, City, State/Province/Region, Postal Code, Country) */}
            <Form.Item label="Physical Address" name="Physical Address">
              <Form.Item
                noStyle
                rules={[{ required: true, message: "Street is required" }]}
              >
                <Input
                  placeholder="Street Address"
                  value={formData?.street}
                  onChange={e => {
                    handleChange("street", e.target.value);
                  }}
                />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item
                  noStyle
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input
                    placeholder="City"
                    value={formData?.city}
                    onChange={e => {
                      handleChange("city", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "State"]}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "State/Providence/Region is required"
                    }
                  ]}
                >
                  <Input
                    placeholder="State/Providence/Region"
                    name="region"
                    value={formData?.region}
                    onChange={e => handleChange("region", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "zip"]}
                  noStyle
                  rules={[
                    { required: true, message: "Postal Code is required" }
                  ]}
                >
                  <Input
                    placeholder="Postal Code"
                    value={formData?.postalCode}
                    onChange={e => {
                      handleChange("postalCode", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "country"]}
                  noStyle
                  rules={[
                    { required: true, message: "Country Code is required" }
                  ]}
                >
                  <Select
                    placeholder="Country"
                    initialvalues={"US"}
                    value={formData?.iscCountry}
                    onChange={e => handleChange("isoCountry", e)}
                  >
                    <Option value="US">United States</Option>
                    <Option value="AF">Afghanistan</Option>
                    <Option value="AX">Åland Islands</Option>
                    <Option value="AL">Albania</Option>
                    <Option value="DZ">Algeria</Option>
                    <Option value="AS">American Samoa</Option>
                    <Option value="AD">Andorra</Option>
                    <Option value="AO">Angola</Option>
                    <Option value="AI">Anguilla</Option>
                    <Option value="AQ">Antarctica</Option>
                    <Option value="AG">Antigua and Barbuda</Option>
                    <Option value="AR">Argentina</Option>
                    <Option value="AM">Armenia</Option>
                    <Option value="AW">Aruba</Option>
                    <Option value="AU">Australia</Option>
                    <Option value="AT">Austria</Option>
                    <Option value="AZ">Azerbaijan</Option>
                    <Option value="BS">Bahamas</Option>
                    <Option value="BH">Bahrain</Option>
                    <Option value="BD">Bangladesh</Option>
                    <Option value="BB">Barbados</Option>
                    <Option value="BY">Belarus</Option>
                    <Option value="BE">Belgium</Option>
                    <Option value="BZ">Belize</Option>
                    <Option value="BJ">Benin</Option>
                    <Option value="BM">Bermuda</Option>
                    <Option value="BT">Bhutan</Option>
                    <Option value="BO">Bolivia, Plurinational State of</Option>
                    <Option value="BQ">Bonaire, Sint Eustatius and Saba</Option>
                    <Option value="BA">Bosnia and Herzegovina</Option>
                    <Option value="BW">Botswana</Option>
                    <Option value="BV">Bouvet Island</Option>
                    <Option value="BR">Brazil</Option>
                    <Option value="IO">British Indian Ocean Territory</Option>
                    <Option value="BN">Brunei Darussalam</Option>
                    <Option value="BG">Bulgaria</Option>
                    <Option value="BF">Burkina Faso</Option>
                    <Option value="BI">Burundi</Option>
                    <Option value="KH">Cambodia</Option>
                    <Option value="CM">Cameroon</Option>
                    <Option value="CA">Canada</Option>
                    <Option value="CV">Cape Verde</Option>
                    <Option value="KY">Cayman Islands</Option>
                    <Option value="CF">Central African Republic</Option>
                    <Option value="TD">Chad</Option>
                    <Option value="CL">Chile</Option>
                    <Option value="CN">China</Option>
                    <Option value="CX">Christmas Island</Option>
                    <Option value="CC">Cocos (Keeling) Islands</Option>
                    <Option value="CO">Colombia</Option>
                    <Option value="KM">Comoros</Option>
                    <Option value="CG">Congo</Option>
                    <Option value="CD">
                      Congo, the Democratic Republic of the
                    </Option>
                    <Option value="CK">Cook Islands</Option>
                    <Option value="CR">Costa Rica</Option>
                    <Option value="CI">Côte d'Ivoire</Option>
                    <Option value="HR">Croatia</Option>
                    <Option value="CU">Cuba</Option>
                    <Option value="CW">Curaçao</Option>
                    <Option value="CY">Cyprus</Option>
                    <Option value="CZ">Czech Republic</Option>
                    <Option value="DK">Denmark</Option>
                    <Option value="DJ">Djibouti</Option>
                    <Option value="DM">Dominica</Option>
                    <Option value="DO">Dominican Republic</Option>
                    <Option value="EC">Ecuador</Option>
                    <Option value="EG">Egypt</Option>
                    <Option value="SV">El Salvador</Option>
                    <Option value="GQ">Equatorial Guinea</Option>
                    <Option value="ER">Eritrea</Option>
                    <Option value="EE">Estonia</Option>
                    <Option value="ET">Ethiopia</Option>
                    <Option value="FK">Falkland Islands (Malvinas)</Option>
                    <Option value="FO">Faroe Islands</Option>
                    <Option value="FJ">Fiji</Option>
                    <Option value="FI">Finland</Option>
                    <Option value="FR">France</Option>
                    <Option value="GF">French Guiana</Option>
                    <Option value="PF">French Polynesia</Option>
                    <Option value="TF">French Southern Territories</Option>
                    <Option value="GA">Gabon</Option>
                    <Option value="GM">Gambia</Option>
                    <Option value="GE">Georgia</Option>
                    <Option value="DE">Germany</Option>
                    <Option value="GH">Ghana</Option>
                    <Option value="GI">Gibraltar</Option>
                    <Option value="GR">Greece</Option>
                    <Option value="GL">Greenland</Option>
                    <Option value="GD">Grenada</Option>
                    <Option value="GP">Guadeloupe</Option>
                    <Option value="GU">Guam</Option>
                    <Option value="GT">Guatemala</Option>
                    <Option value="GG">Guernsey</Option>
                    <Option value="GN">Guinea</Option>
                    <Option value="GW">Guinea-Bissau</Option>
                    <Option value="GY">Guyana</Option>
                    <Option value="HT">Haiti</Option>
                    <Option value="HM">
                      Heard Island and McDonald Islands
                    </Option>
                    <Option value="VA">Holy See (Vatican City State)</Option>
                    <Option value="HN">Honduras</Option>
                    <Option value="HK">Hong Kong</Option>
                    <Option value="HU">Hungary</Option>
                    <Option value="IS">Iceland</Option>
                    <Option value="IN">India</Option>
                    <Option value="ID">Indonesia</Option>
                    <Option value="IR">Iran, Islamic Republic of</Option>
                    <Option value="IQ">Iraq</Option>
                    <Option value="IE">Ireland</Option>
                    <Option value="IM">Isle of Man</Option>
                    <Option value="IL">Israel</Option>
                    <Option value="IT">Italy</Option>
                    <Option value="JM">Jamaica</Option>
                    <Option value="JP">Japan</Option>
                    <Option value="JE">Jersey</Option>
                    <Option value="JO">Jordan</Option>
                    <Option value="KZ">Kazakhstan</Option>
                    <Option value="KE">Kenya</Option>
                    <Option value="KI">Kiribati</Option>
                    <Option value="KP">
                      Korea, Democratic People's Republic of
                    </Option>
                    <Option value="KR">Korea, Republic of</Option>
                    <Option value="KW">Kuwait</Option>
                    <Option value="KG">Kyrgyzstan</Option>
                    <Option value="LA">Lao People's Democratic Republic</Option>
                    <Option value="LV">Latvia</Option>
                    <Option value="LB">Lebanon</Option>
                    <Option value="LS">Lesotho</Option>
                    <Option value="LR">Liberia</Option>
                    <Option value="LY">Libya</Option>
                    <Option value="LI">Liechtenstein</Option>
                    <Option value="LT">Lithuania</Option>
                    <Option value="LU">Luxembourg</Option>
                    <Option value="MO">Macao</Option>
                    <Option value="MK">
                      Macedonia, the former Yugoslav Republic of
                    </Option>
                    <Option value="MG">Madagascar</Option>
                    <Option value="MW">Malawi</Option>
                    <Option value="MY">Malaysia</Option>
                    <Option value="MV">Maldives</Option>
                    <Option value="ML">Mali</Option>
                    <Option value="MT">Malta</Option>
                    <Option value="MH">Marshall Islands</Option>
                    <Option value="MQ">Martinique</Option>
                    <Option value="MR">Mauritania</Option>
                    <Option value="MU">Mauritius</Option>
                    <Option value="YT">Mayotte</Option>
                    <Option value="MX">Mexico</Option>
                    <Option value="FM">Micronesia, Federated States of</Option>
                    <Option value="MD">Moldova, Republic of</Option>
                    <Option value="MC">Monaco</Option>
                    <Option value="MN">Mongolia</Option>
                    <Option value="ME">Montenegro</Option>
                    <Option value="MS">Montserrat</Option>
                    <Option value="MA">Morocco</Option>
                    <Option value="MZ">Mozambique</Option>
                    <Option value="MM">Myanmar</Option>
                    <Option value="NA">Namibia</Option>
                    <Option value="NR">Nauru</Option>
                    <Option value="NP">Nepal</Option>
                    <Option value="NL">Netherlands</Option>
                    <Option value="NC">New Caledonia</Option>
                    <Option value="NZ">New Zealand</Option>
                    <Option value="NI">Nicaragua</Option>
                    <Option value="NE">Niger</Option>
                    <Option value="NG">Nigeria</Option>
                    <Option value="NU">Niue</Option>
                    <Option value="NF">Norfolk Island</Option>
                    <Option value="MP">Northern Mariana Islands</Option>
                    <Option value="NO">Norway</Option>
                    <Option value="OM">Oman</Option>
                    <Option value="PK">Pakistan</Option>
                    <Option value="PW">Palau</Option>
                    <Option value="PS">Palestinian Territory, Occupied</Option>
                    <Option value="PA">Panama</Option>
                    <Option value="PG">Papua New Guinea</Option>
                    <Option value="PY">Paraguay</Option>
                    <Option value="PE">Peru</Option>
                    <Option value="PH">Philippines</Option>
                    <Option value="PN">Pitcairn</Option>
                    <Option value="PL">Poland</Option>
                    <Option value="PT">Portugal</Option>
                    <Option value="PR">Puerto Rico</Option>
                    <Option value="QA">Qatar</Option>
                    <Option value="RE">Réunion</Option>
                    <Option value="RO">Romania</Option>
                    <Option value="RU">Russian Federation</Option>
                    <Option value="RW">Rwanda</Option>
                    <Option value="BL">Saint Barthélemy</Option>
                    <Option value="SH">
                      Saint Helena, Ascension and Tristan da Cunha
                    </Option>
                    <Option value="KN">Saint Kitts and Nevis</Option>
                    <Option value="LC">Saint Lucia</Option>
                    <Option value="MF">Saint Martin (French part)</Option>
                    <Option value="PM">Saint Pierre and Miquelon</Option>
                    <Option value="VC">Saint Vincent and the Grenadines</Option>
                    <Option value="WS">Samoa</Option>
                    <Option value="SM">San Marino</Option>
                    <Option value="ST">Sao Tome and Principe</Option>
                    <Option value="SA">Saudi Arabia</Option>
                    <Option value="SN">Senegal</Option>
                    <Option value="RS">Serbia</Option>
                    <Option value="SC">Seychelles</Option>
                    <Option value="SL">Sierra Leone</Option>
                    <Option value="SG">Singapore</Option>
                    <Option value="SX">Sint Maarten (Dutch part)</Option>
                    <Option value="SK">Slovakia</Option>
                    <Option value="SI">Slovenia</Option>
                    <Option value="SB">Solomon Islands</Option>
                    <Option value="SO">Somalia</Option>
                    <Option value="ZA">South Africa</Option>
                    <Option value="GS">
                      South Georgia and the South Sandwich Islands
                    </Option>
                    <Option value="SS">South Sudan</Option>
                    <Option value="ES">Spain</Option>
                    <Option value="LK">Sri Lanka</Option>
                    <Option value="SD">Sudan</Option>
                    <Option value="SR">Suriname</Option>
                    <Option value="SJ">Svalbard and Jan Mayen</Option>
                    <Option value="SZ">Swaziland</Option>
                    <Option value="SE">Sweden</Option>
                    <Option value="CH">Switzerland</Option>
                    <Option value="SY">Syrian Arab Republic</Option>
                    <Option value="TW">Taiwan, Province of China</Option>
                    <Option value="TJ">Tajikistan</Option>
                    <Option value="TZ">Tanzania, United Republic of</Option>
                    <Option value="TH">Thailand</Option>
                    <Option value="TL">Timor-Leste</Option>
                    <Option value="TG">Togo</Option>
                    <Option value="TK">Tokelau</Option>
                    <Option value="TO">Tonga</Option>
                    <Option value="TT">Trinidad and Tobago</Option>
                    <Option value="TN">Tunisia</Option>
                    <Option value="TR">Turkey</Option>
                    <Option value="TM">Turkmenistan</Option>
                    <Option value="TC">Turks and Caicos Islands</Option>
                    <Option value="TV">Tuvalu</Option>
                    <Option value="UG">Uganda</Option>
                    <Option value="UA">Ukraine</Option>
                    <Option value="AE">United Arab Emirates</Option>
                    <Option value="GB">United Kingdom</Option>
                    <Option value="UM">
                      United States Minor Outlying Islands
                    </Option>
                    <Option value="UY">Uruguay</Option>
                    <Option value="UZ">Uzbekistan</Option>
                    <Option value="VU">Vanuatu</Option>
                    <Option value="VE">
                      Venezuela, Bolivarian Republic of
                    </Option>
                    <Option value="VN">Viet Nam</Option>
                    <Option value="VG">Virgin Islands, British</Option>
                    <Option value="VI">Virgin Islands, U.S.</Option>
                    <Option value="WF">Wallis and Futuna</Option>
                    <Option value="EH">Western Sahara</Option>
                    <Option value="YE">Yemen</Option>
                    <Option value="ZM">Zambia</Option>
                    <Option value="ZW">Zimbabwe</Option>
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item label="Name">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item
                  noStyle
                  name="First Name"
                  rules={[
                    {
                      required: true,
                      message: " first name is required"
                    }
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    value={formData?.customerFirstName}
                    onChange={e => {
                      handleChange("customerFirstName", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  name=" Last Name"
                  rules={[
                    {
                      required: true,
                      message: "last name is required"
                    }
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    value={formData?.customerLastName}
                    onChange={e => {
                      handleChange("customerLastName", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="Email"
              name="Email"
              rules={[
                {
                  required: true,
                  message: "Email is required"
                }
              ]}
            >
              <Input
                placeholder="Please Enter your Email"
                value={formData?.email}
                onChange={e => {
                  handleChange("email", e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required"
                }
              ]}
            >
              <Input
                placeholder="Phone Number"
                value={formData?.phoneNumber}
                onChange={e => {
                  handleChange("phoneNumber", e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Campaign Use Description"
              name="campaignDescription"
              rules={[
                {
                  required: true,
                  message: "Please provide Campaign Description"
                }
              ]}
            >
              <Input.TextArea
                value={formData?.campaignDescription}
                onChange={e => {
                  handleChange("campaignDescription", e.target.value);
                }}
              ></Input.TextArea>
            </Form.Item>

            <Form.Item label="Campaign Message Samples">
              <Form.Item
                label="Campaign Message Samples"
                noStyle
                name="campaignMessageSample1"
                rules={[
                  {
                    max: 1024,
                    message: "Please provide First Campaign Message Sample"
                  }
                ]}
              >
                <Input.TextArea
                  value={formData?.campaignMessageSample1}
                  onChange={e => {
                    handleChange("campaignMessageSample1", e.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>
              <br />
              <Form.Item
                noStyle
                label="Campaign Message Samples"
                name="campaignMessageSample2"
                rules={[
                  {
                    max: 1024,
                    message: "Please provide Second Campaign Message Sample"
                  }
                ]}
              >
                <Input.TextArea
                  value={formData?.campaignMessageSample2}
                  onChange={e => {
                    handleChange("campaignMessageSample2", e.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>
            </Form.Item>
          </div>

          <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
            <div>
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => refreshStart()}
              >
                Go Back to Selection
              </Button>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                submit
              </Button>
            </div>
          </Form.Item>
        </Form>
                
      <Modal
        title="Price Acknowledgement"
        open={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
        <br />
        <p>
          For more info about 10 DLC click{" "}
          <a target="_blank" href={helpDocs[props.platform]} rel="noreferrer">
            here.
          </a>
        </p>
      </Modal>

      </Card>
    </div>
  );
};

export default StarterForm;

import React, { useEffect, useState } from "react";
import MainForm from "./DLCForm/MainForm";
import StarterBrand from "./DLCForm/StarterBrand";
import Complete from "./DLCForm/Complete";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Radio, Layout,  Button, Space, Card, Row, Col, Divider } from "antd";

import "./App.css";
// import SiteHeader from '../Components/SiteHeader';
// import SiteFooter from '../Components/SiteFooter';
const { Content } = Layout;

const App = () => {
  const [platform, setPlatform] = useState("");
  const [uuid, setUUID] = useState();

  const [value, setValue] = useState();
  const [step, setStep] = useState(0);

  const helpDocs = {
    fyf: "https://intercom.help/fix-your-funnel/en/articles/5589045-10dlc-registation-information",
    engage:
      "http://help.engagecrm.io/en/articles/5589097-10dlc-registration-information",
    yeti: "http://help.yetitext.com/en/articles/5589515-what-is-a2p-10dlc"
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let platform = params.get("platform"); // 123
    let uuid = params.get("uuid"); // 123

    setPlatform(platform);
    setUUID(uuid);
    document.body.className = platform;
    return () => {
      document.body.className = "";
    };
  }, []);

  const onChange = e => {
    setValue(e.target.value);
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">

          <div className="form-wrapper">
            {step === 0 && (
              <div>
                <Card>
                  <Row>
                    <Col span="8"></Col>
                    <Col span="8" style={{ textAlign: "center" }}>
                      <h1>Local Phone Number Text Messaging Registration</h1>
                    </Col>
                  </Row>

                  <Divider></Divider>

                  <h4>
Choose the option that fits your business. 
For business entities (ie- LLC, Corporations, and Partnerships) sending less than 6000 messages in a given day from US phone numbers, you will need to register as a “Low Volume Standard Brand” in order to avoid carriers blocking your messages. If you plan on or are currently sending 6000+ messages in any given day, your business will need to register as a “High Volume Standard Brand” brand.
If you are doing business as a sole proprietor and sending text messages from US phone numbers no action is needed at this time  
 <br></br> 
                    <a target="_blank" href={helpDocs[platform]} rel="noreferrer">
                    support docs
          </a>
                  </h4>
                  <Divider></Divider>

                  <Row>
                    <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                      <Radio value={"low_standard"}>
                      * I am a business sending less than 6000 messages a day. Register me as a Low Volume Standard Brand
                        </Radio>
                        <Radio value={"standard"}>
                        * I am a business sending more than 6000 messages a day. Register me as a High Volume Standard Brand 
                        </Radio>
                         <Radio value={"starter"} >
                        * I am a business that does not have a EIN or operating as a Sole Proprietor. Register me as a Sole Proprietor 
                        </Radio> 
                      </Space>
                    </Radio.Group>{" "}
                    {/* <MainForm uuid={uuid} platform={platform} /> */}
                  </Row>
                  <Divider></Divider>

                  <Row>
                    <Button
                      disabled={!value}
                      type="primary"
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Continue
                    </Button>
                  </Row>
                </Card>
              </div>
            )}
            {step === 1 && (value === "standard" || value === "low_standard") && (
              <MainForm uuid={uuid} platform={platform} type={value} />
            )}
            {step === 1 && (value !== "standard" || value !== "low_standard") && (
              <StarterBrand uuid={uuid} platform={platform} type={value} />
            )} 
          

          </div>
          
        </Route>

        <Route exact path="/Complete">
          <div className="form-wrapper confetti">
            <Complete platform={platform} />
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  Card,
  Divider,
  Modal,
  message,
  Alert,
  Collapse,
  Checkbox,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import axios from "axios";
import { useHistory } from "react-router-dom";

import "./MainForm.scss";

const { Panel } = Collapse;
const { confirm } = Modal;

const { Option } = Select;

const helpDocs = {
  fyf: "https://intercom.help/fix-your-funnel/en/articles/5589045-10dlc-registation-information",
  engage:
    "http://help.engagecrm.io/en/articles/5589097-10dlc-registration-information",
  yeti: "http://help.yetitext.com/en/articles/5589515-what-is-a2p-10dlc",
};

const MainForm = (props) => {
  const [formData, setData] = useState({});
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [helpModal, setHelpModal] = useState(true);
  const [modalText, setModalText] = useState(
    `"By submitting this form, you consent to a one-time charge of $44.00 for the review process. Approval is not guaranteed and our support team will reach out if necessary. To avoid additional charges, kindly refrain from submitting multiple forms."`
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setModalText("Form is being submitted please wait to be redirected");

    setConfirmLoading(true);
    try {
      await sendToServer();
      setVisible(false);
      setConfirmLoading(false);
      history.replace(`/complete?platform=${props.platform}`);
    } catch (error) {
      setModalText(
        "There has been an error please contact support. Please do not submit multiple forms to avoid excess charges to your account."
      );

      const errorMessage =
        error.message ?? "Oops Something went wrong. Please contact support.";
      message.error(errorMessage);
      setVisible(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (name, value) => {
    console.log(name, value)
    const data = { ...formData };
    data[name] = value;
    setData(data);
  };

  const combineMessageTypes = (values) => {
    const clone = { ...values };
    let campaignMessageSamples = [];
    if (clone.campaignMessageSample1 && clone.campaignMessageSample2) {
      campaignMessageSamples.push(
        clone.campaignMessageSample1,
        clone.campaignMessageSample2
      );
    } else {
      return;
    }
    clone.business_registration_identifier = "EIN";

    clone.campaignMessageSamples = campaignMessageSamples;
    delete clone.campaignMessageSample1;
    delete clone.campaignMessageSample2;
    return clone;
  };

  const combineMessageFlow = (values) => {
    console.log(values)
    let message = "";
    const messageFlow = {
      messageFlow1 : " My contacts opt-in by providing their phone number to the company and verbally agree to start recieving text messages.",
      messageFlow2 : " My contacts fill out a form on a website or in-person and add their phone number to start recieving text messages",
      messageFlow3 : " My contacts opt-in by sending a text message to one of our business numbers."
    }
    if(values.messageFlow1){
      message =  message +messageFlow.messageFlow1
    }
    if(values.messageFlow2){
      message =  message +messageFlow.messageFlow2
    }
    if(values.messageFlow3){
      message =  message +messageFlow.messageFlow3
    }
    values.messageFlow = message;
    delete values.messageFlow1
    delete values.messageFlow2
    delete values.messageFlow3

    return values
  }


  const sendToServer = async () => {
    let values = { ...formData };
    
    values = combineMessageFlow(values);
    const { uuid, platform, type } = props;
    console.log(type)
    if(type === "standard"){
      values.skipSecondaryVetting = false;
    }else{
      values.skipSecondaryVetting = true;
      values.usAppToPersonUsecase = "LOW_VOLUME";
    }
    console.log(values)
    const resData = combineMessageTypes(values);


    if (!uuid) {
      throw new Error("invalid url");
    }
    let res = await axios.post(
      `https://hixazgllr5.execute-api.us-west-2.amazonaws.com/prod/10dlc-registration/${platform}/${uuid}`,
      resData
    );
    const body = JSON.parse(res.data.body);

    if (res.data.statusCode === 422) {
      throw new Error(body.reason);
    }

    if (res.data.statusCode !== 200 && body.success !== true) {
      throw new Error();
    }
    return values;
  };

  const refreshStart = () => {
    history.go(0);
  };

  const createUsertoAppUseCase = () => {
    const us_app_to_person_usecases = [
     
      {
        code: "MIXED",
        name: "Mixed",
        description:
          "Mixed messaging reserved for specific consumer service industry",
        post_approval_required: false,
      },
      {
        code: "CUSTOMER_CARE",
        name: "Customer Care",
        description:
          "All customer care messaging, including account management and support",
        post_approval_required: false,
      },
      {
        code: "MARKETING",
        name: "Marketing",
        description:
          "Any communication with marketing and/or promotional content",
        post_approval_required: false,
      },
     
      {
        code: "ACCOUNT_NOTIFICATION",
        name: "Account Notification",
        description:
          "All reminders, alerts, and notifications. (Examples include: flight delayed, hotel booked, appointment reminders.)",
        post_approval_required: false,
      },
      {
        code: "CHARITY",
        name: "Charity (5013C Charity Does not include: Religious organizations)",
        description:
          "Includes:  5013C Charity Does not include: Religious organizations",
        post_approval_required: false,
      },
    
      {
        code: "DELIVERY_NOTIFICATION",
        name: "Delivery Notification",
        description:
          "Information about the status of the delivery of a product or service",
        post_approval_required: false,
      },
      {
        code: "FRAUD_ALERT",
        name: "Fraud Alert Messaging",
        description: "Fraud alert notification",
        post_approval_required: false,
      },
      {
        code: "HIGHER_EDUCATION",
        name: "Higher Education",
        description:
          'For campaigns created on behalf of Colleges or Universities and will also include School Districts etc that fall outside of any "free to the consumer" messaging model',
        post_approval_required: false,
      },
      {
        code: "POLLING_VOTING",
        name: "Polling and voting",
        description: "Polling and voting",
        post_approval_required: false,
      },
      {
        code: "PUBLIC_SERVICE_ANNOUNCEMENT",
        name: "Public Service Announcement",
        description:
          "An informational message that is meant to raise the audience awareness about an important issue",
        post_approval_required: false,
      },
      {
        code: "SECURITY_ALERT",
        name: "Security Alert",
        description:
          "A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take",
        post_approval_required: false,
      },
      {
        code: "2FA",
        name: "Two-Factor authentication (2FA)",
        description:
          "Two-Factor authentication, one-time use password, password reset",
        post_approval_required: false,
      },
    ];

    return us_app_to_person_usecases.map((useCase) => {
      return <Option value={useCase.code}>{useCase.name}</Option>;
    });
  };

  const onFinish = async () => {
    const {type} = props
    if(type == "low_standard"){
      setModalText("By submitting this form, you consent to a one-time charge of $15.00 for the review process. Approval is not guaranteed and our support team will reach out if necessary. To avoid additional charges, kindly refrain from submitting multiple forms.")
    }
    await showModal(formData);
  };

  const onFinishFailed = async (errorInfo) => {
    console.log(errorInfo)
  };

  return (
    <div className="card-wrapper">
      <Card>
        <Form
          name="standard"
          onFinish={onFinish}
          className="form-wrapper"
          layout="vertical"
          onFinishFailed={onFinishFailed}
        >
          <div style={{textAlign: "center"}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 style={{ marginBottom: "0" }}>
                Local Phone Number Text Messaging Registration
              </h1>{" "}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setHelpModal(true)}
              >
                {" "}
                &nbsp; &nbsp;
                <QuestionCircleOutlined />
              </div>
            </div>
            <b>
              <p>
                Fill out the following information to register with texting
                carriers and start texting your customers!
              </p>
            </b>
          </div>
          <Divider></Divider>

          {/* Business Name */}
          <div>
            <br />
            <Form.Item
              tooltip="This is a required field, Please add the Business name that is registered with the US government "
              label="Legal Business Name"
              name="business_name"
              rules={[
                {
                  required: true,
                  message: "Please input your Business Name",
                },
              ]}
            >
              <Input
                value={formData?.business_name}
                name="business_name"
                onChange={(e) => {
                  handleChange("business_name", e.target.value);
                }}
              />
            </Form.Item>

            { /* Physical Address (Street, City, State/Province/Region, Postal Code, Country) */}
            <Form.Item label="Physical Address" name="Physical Address" tooltip="This is a required field, Please add the business address that is registered with the US government "
            >
              <Form.Item
                noStyle
                rules={[{ required: true, message: "Street is required" }]}
              >
                <Input
                  placeholder="Street Address"
                  value={formData?.street}
                  onChange={(e) => {
                    handleChange("street", e.target.value);
                  }}
                />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item
                  noStyle
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input
                    placeholder="City"
                    value={formData?.city}
                    onChange={(e) => {
                      handleChange("city", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "State"]}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "State/Providence/Region is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="State/Providence/Region"
                    name="region"
                    value={formData?.region}
                    onChange={(e) => handleChange("region", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "zip"]}
                  noStyle
                  rules={[
                    { required: true, message: "Postal code is required" },
                  ]}
                >
                  <Input
                    placeholder="Postal Code"
                    value={formData?.postalCode}
                    onChange={(e) => {
                      handleChange("postalCode", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["address", "country"]}
                  noStyle
                  rules={[
                    { required: true, message: "Country cede is required" },
                  ]}
                >
                  <Select
                    placeholder="Country"
                    initialvalues={"US"}
                    value={formData?.isoCountry}
                    onChange={(e) => handleChange("isoCountry", e)}
                  >
                    <Option value="US">United States</Option>
                    <Option value="AF">Afghanistan</Option>
                    <Option value="AX">Åland Islands</Option>
                    <Option value="AL">Albania</Option>
                    <Option value="DZ">Algeria</Option>
                    <Option value="AS">American Samoa</Option>
                    <Option value="AD">Andorra</Option>
                    <Option value="AO">Angola</Option>
                    <Option value="AI">Anguilla</Option>
                    <Option value="AQ">Antarctica</Option>
                    <Option value="AG">Antigua and Barbuda</Option>
                    <Option value="AR">Argentina</Option>
                    <Option value="AM">Armenia</Option>
                    <Option value="AW">Aruba</Option>
                    <Option value="AU">Australia</Option>
                    <Option value="AT">Austria</Option>
                    <Option value="AZ">Azerbaijan</Option>
                    <Option value="BS">Bahamas</Option>
                    <Option value="BH">Bahrain</Option>
                    <Option value="BD">Bangladesh</Option>
                    <Option value="BB">Barbados</Option>
                    <Option value="BY">Belarus</Option>
                    <Option value="BE">Belgium</Option>
                    <Option value="BZ">Belize</Option>
                    <Option value="BJ">Benin</Option>
                    <Option value="BM">Bermuda</Option>
                    <Option value="BT">Bhutan</Option>
                    <Option value="BO">Bolivia, Plurinational State of</Option>
                    <Option value="BQ">Bonaire, Sint Eustatius and Saba</Option>
                    <Option value="BA">Bosnia and Herzegovina</Option>
                    <Option value="BW">Botswana</Option>
                    <Option value="BV">Bouvet Island</Option>
                    <Option value="BR">Brazil</Option>
                    <Option value="IO">British Indian Ocean Territory</Option>
                    <Option value="BN">Brunei Darussalam</Option>
                    <Option value="BG">Bulgaria</Option>
                    <Option value="BF">Burkina Faso</Option>
                    <Option value="BI">Burundi</Option>
                    <Option value="KH">Cambodia</Option>
                    <Option value="CM">Cameroon</Option>
                    <Option value="CA">Canada</Option>
                    <Option value="CV">Cape Verde</Option>
                    <Option value="KY">Cayman Islands</Option>
                    <Option value="CF">Central African Republic</Option>
                    <Option value="TD">Chad</Option>
                    <Option value="CL">Chile</Option>
                    <Option value="CN">China</Option>
                    <Option value="CX">Christmas Island</Option>
                    <Option value="CC">Cocos (Keeling) Islands</Option>
                    <Option value="CO">Colombia</Option>
                    <Option value="KM">Comoros</Option>
                    <Option value="CG">Congo</Option>
                    <Option value="CD">
                      Congo, the Democratic Republic of the
                    </Option>
                    <Option value="CK">Cook Islands</Option>
                    <Option value="CR">Costa Rica</Option>
                    <Option value="CI">Côte d'Ivoire</Option>
                    <Option value="HR">Croatia</Option>
                    <Option value="CU">Cuba</Option>
                    <Option value="CW">Curaçao</Option>
                    <Option value="CY">Cyprus</Option>
                    <Option value="CZ">Czech Republic</Option>
                    <Option value="DK">Denmark</Option>
                    <Option value="DJ">Djibouti</Option>
                    <Option value="DM">Dominica</Option>
                    <Option value="DO">Dominican Republic</Option>
                    <Option value="EC">Ecuador</Option>
                    <Option value="EG">Egypt</Option>
                    <Option value="SV">El Salvador</Option>
                    <Option value="GQ">Equatorial Guinea</Option>
                    <Option value="ER">Eritrea</Option>
                    <Option value="EE">Estonia</Option>
                    <Option value="ET">Ethiopia</Option>
                    <Option value="FK">Falkland Islands (Malvinas)</Option>
                    <Option value="FO">Faroe Islands</Option>
                    <Option value="FJ">Fiji</Option>
                    <Option value="FI">Finland</Option>
                    <Option value="FR">France</Option>
                    <Option value="GF">French Guiana</Option>
                    <Option value="PF">French Polynesia</Option>
                    <Option value="TF">French Southern Territories</Option>
                    <Option value="GA">Gabon</Option>
                    <Option value="GM">Gambia</Option>
                    <Option value="GE">Georgia</Option>
                    <Option value="DE">Germany</Option>
                    <Option value="GH">Ghana</Option>
                    <Option value="GI">Gibraltar</Option>
                    <Option value="GR">Greece</Option>
                    <Option value="GL">Greenland</Option>
                    <Option value="GD">Grenada</Option>
                    <Option value="GP">Guadeloupe</Option>
                    <Option value="GU">Guam</Option>
                    <Option value="GT">Guatemala</Option>
                    <Option value="GG">Guernsey</Option>
                    <Option value="GN">Guinea</Option>
                    <Option value="GW">Guinea-Bissau</Option>
                    <Option value="GY">Guyana</Option>
                    <Option value="HT">Haiti</Option>
                    <Option value="HM">
                      Heard Island and McDonald Islands
                    </Option>
                    <Option value="VA">Holy See (Vatican City State)</Option>
                    <Option value="HN">Honduras</Option>
                    <Option value="HK">Hong Kong</Option>
                    <Option value="HU">Hungary</Option>
                    <Option value="IS">Iceland</Option>
                    <Option value="IN">India</Option>
                    <Option value="ID">Indonesia</Option>
                    <Option value="IR">Iran, Islamic Republic of</Option>
                    <Option value="IQ">Iraq</Option>
                    <Option value="IE">Ireland</Option>
                    <Option value="IM">Isle of Man</Option>
                    <Option value="IL">Israel</Option>
                    <Option value="IT">Italy</Option>
                    <Option value="JM">Jamaica</Option>
                    <Option value="JP">Japan</Option>
                    <Option value="JE">Jersey</Option>
                    <Option value="JO">Jordan</Option>
                    <Option value="KZ">Kazakhstan</Option>
                    <Option value="KE">Kenya</Option>
                    <Option value="KI">Kiribati</Option>
                    <Option value="KP">
                      Korea, Democratic People's Republic of
                    </Option>
                    <Option value="KR">Korea, Republic of</Option>
                    <Option value="KW">Kuwait</Option>
                    <Option value="KG">Kyrgyzstan</Option>
                    <Option value="LA">Lao People's Democratic Republic</Option>
                    <Option value="LV">Latvia</Option>
                    <Option value="LB">Lebanon</Option>
                    <Option value="LS">Lesotho</Option>
                    <Option value="LR">Liberia</Option>
                    <Option value="LY">Libya</Option>
                    <Option value="LI">Liechtenstein</Option>
                    <Option value="LT">Lithuania</Option>
                    <Option value="LU">Luxembourg</Option>
                    <Option value="MO">Macao</Option>
                    <Option value="MK">
                      Macedonia, the former Yugoslav Republic of
                    </Option>
                    <Option value="MG">Madagascar</Option>
                    <Option value="MW">Malawi</Option>
                    <Option value="MY">Malaysia</Option>
                    <Option value="MV">Maldives</Option>
                    <Option value="ML">Mali</Option>
                    <Option value="MT">Malta</Option>
                    <Option value="MH">Marshall Islands</Option>
                    <Option value="MQ">Martinique</Option>
                    <Option value="MR">Mauritania</Option>
                    <Option value="MU">Mauritius</Option>
                    <Option value="YT">Mayotte</Option>
                    <Option value="MX">Mexico</Option>
                    <Option value="FM">Micronesia, Federated States of</Option>
                    <Option value="MD">Moldova, Republic of</Option>
                    <Option value="MC">Monaco</Option>
                    <Option value="MN">Mongolia</Option>
                    <Option value="ME">Montenegro</Option>
                    <Option value="MS">Montserrat</Option>
                    <Option value="MA">Morocco</Option>
                    <Option value="MZ">Mozambique</Option>
                    <Option value="MM">Myanmar</Option>
                    <Option value="NA">Namibia</Option>
                    <Option value="NR">Nauru</Option>
                    <Option value="NP">Nepal</Option>
                    <Option value="NL">Netherlands</Option>
                    <Option value="NC">New Caledonia</Option>
                    <Option value="NZ">New Zealand</Option>
                    <Option value="NI">Nicaragua</Option>
                    <Option value="NE">Niger</Option>
                    <Option value="NG">Nigeria</Option>
                    <Option value="NU">Niue</Option>
                    <Option value="NF">Norfolk Island</Option>
                    <Option value="MP">Northern Mariana Islands</Option>
                    <Option value="NO">Norway</Option>
                    <Option value="OM">Oman</Option>
                    <Option value="PK">Pakistan</Option>
                    <Option value="PW">Palau</Option>
                    <Option value="PS">Palestinian Territory, Occupied</Option>
                    <Option value="PA">Panama</Option>
                    <Option value="PG">Papua New Guinea</Option>
                    <Option value="PY">Paraguay</Option>
                    <Option value="PE">Peru</Option>
                    <Option value="PH">Philippines</Option>
                    <Option value="PN">Pitcairn</Option>
                    <Option value="PL">Poland</Option>
                    <Option value="PT">Portugal</Option>
                    <Option value="PR">Puerto Rico</Option>
                    <Option value="QA">Qatar</Option>
                    <Option value="RE">Réunion</Option>
                    <Option value="RO">Romania</Option>
                    <Option value="RU">Russian Federation</Option>
                    <Option value="RW">Rwanda</Option>
                    <Option value="BL">Saint Barthélemy</Option>
                    <Option value="SH">
                      Saint Helena, Ascension and Tristan da Cunha
                    </Option>
                    <Option value="KN">Saint Kitts and Nevis</Option>
                    <Option value="LC">Saint Lucia</Option>
                    <Option value="MF">Saint Martin (French part)</Option>
                    <Option value="PM">Saint Pierre and Miquelon</Option>
                    <Option value="VC">Saint Vincent and the Grenadines</Option>
                    <Option value="WS">Samoa</Option>
                    <Option value="SM">San Marino</Option>
                    <Option value="ST">Sao Tome and Principe</Option>
                    <Option value="SA">Saudi Arabia</Option>
                    <Option value="SN">Senegal</Option>
                    <Option value="RS">Serbia</Option>
                    <Option value="SC">Seychelles</Option>
                    <Option value="SL">Sierra Leone</Option>
                    <Option value="SG">Singapore</Option>
                    <Option value="SX">Sint Maarten (Dutch part)</Option>
                    <Option value="SK">Slovakia</Option>
                    <Option value="SI">Slovenia</Option>
                    <Option value="SB">Solomon Islands</Option>
                    <Option value="SO">Somalia</Option>
                    <Option value="ZA">South Africa</Option>
                    <Option value="GS">
                      South Georgia and the South Sandwich Islands
                    </Option>
                    <Option value="SS">South Sudan</Option>
                    <Option value="ES">Spain</Option>
                    <Option value="LK">Sri Lanka</Option>
                    <Option value="SD">Sudan</Option>
                    <Option value="SR">Suriname</Option>
                    <Option value="SJ">Svalbard and Jan Mayen</Option>
                    <Option value="SZ">Swaziland</Option>
                    <Option value="SE">Sweden</Option>
                    <Option value="CH">Switzerland</Option>
                    <Option value="SY">Syrian Arab Republic</Option>
                    <Option value="TW">Taiwan, Province of China</Option>
                    <Option value="TJ">Tajikistan</Option>
                    <Option value="TZ">Tanzania, United Republic of</Option>
                    <Option value="TH">Thailand</Option>
                    <Option value="TL">Timor-Leste</Option>
                    <Option value="TG">Togo</Option>
                    <Option value="TK">Tokelau</Option>
                    <Option value="TO">Tonga</Option>
                    <Option value="TT">Trinidad and Tobago</Option>
                    <Option value="TN">Tunisia</Option>
                    <Option value="TR">Turkey</Option>
                    <Option value="TM">Turkmenistan</Option>
                    <Option value="TC">Turks and Caicos Islands</Option>
                    <Option value="TV">Tuvalu</Option>
                    <Option value="UG">Uganda</Option>
                    <Option value="UA">Ukraine</Option>
                    <Option value="AE">United Arab Emirates</Option>
                    <Option value="GB">United Kingdom</Option>
                    <Option value="UM">
                      United States Minor Outlying Islands
                    </Option>
                    <Option value="UY">Uruguay</Option>
                    <Option value="UZ">Uzbekistan</Option>
                    <Option value="VU">Vanuatu</Option>
                    <Option value="VE">
                      Venezuela, Bolivarian Republic of
                    </Option>
                    <Option value="VN">Viet Nam</Option>
                    <Option value="VG">Virgin Islands, British</Option>
                    <Option value="VI">Virgin Islands, U.S.</Option>
                    <Option value="WF">Wallis and Futuna</Option>
                    <Option value="EH">Western Sahara</Option>
                    <Option value="YE">Yemen</Option>
                    <Option value="ZM">Zambia</Option>
                    <Option value="ZW">Zimbabwe</Option>
                  </Select>
                </Form.Item>
              </div>
              <Collapse >
                <Panel header="EIN Business Help" key="1">
                  <Alert
                    message="Success Tips"
                    description="To get the highest A2P messaging limits for your brand, your legal business name, business address, and US EIN must match what is filed with the US Government. You can find your EIN on your W2 or W9"
                    type="info"
                    showIcon
                  />
                  <p > To find the exact details you need to register, we recommend you use this <a target="_blank" href="https://www.hipaaspace.com/ein/ein_verification/" rel="noreferrer">EIN lookup service</a></p>
                  <ul>

                    <li> This 3rd party service only applies to United States EIN / Tax IDs.</li>
                    <li>In the lookup, enter your customer's EIN / Tax ID in order to locate the business listing. It is possible not all EIN / Tax IDs are available in the 3rd party lookup service.</li>
                    <li>Enter the information we ask for exactly as it appears in the listing.</li>
                  </ul>

                </Panel>
              </Collapse>
            </Form.Item>

            <Form.Item
              label="Business email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Business email is required",
                },
              ]}
            >
              <Input
                placeholder="Enter email"
                value={formData?.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Industry"
              name="business_industry"
              rules={[
                {
                  required: true,
                  message: "Please choose your industry",
                },
              ]}
            >
              <Select
                value={formData?.business_industry}
                onChange={(e) => handleChange("business_industry", e)}
                placeholder="select business Industry"
                style={{ overflowY: "scroll", maxHeight: 250 }}
              >
                <Option value="AUTOMOTIVE">Automotive</Option>
                <Option value="AGRICULTURE">Agricuture</Option>
                <Option value="BANKING">Banking</Option>
                <Option value="CONSUMER">Consumer</Option>
                <Option value="EDUCATION">Education</Option>
                <Option value="ENGINEERING">Engineering</Option>
                <Option value="ENERGY">Energy</Option>
                <Option value="OIL_AND_GAS">Oil and gas</Option>
                <Option value="FAST_MOVING_CONSUMER_GOODS">
                  Fast moving consumer goods
                </Option>
                <Option value="FINANCIAL">Financial</Option>
                <Option value="FINTECH">Fintech</Option>
                <Option value="FOOD_AND_BEVERAGE">Food and beverage</Option>
                <Option value="GOVERNMENT">Government</Option>
                <Option value="HEALTHCARE">Healthcare</Option>
                <Option value="HOSPITALITY">Hospitality</Option>
                <Option value="INSURANCE">Insurance</Option>
                <Option value="LEGAL">Legal</Option>
                <Option value="MANUFACTURING">Manufacturing</Option>
                <Option value="MEDIA">Media</Option>
                <Option value="ONLINE">Online</Option>
                <Option value="RAW_MATERIALS">Raw materials</Option>
                <Option value="REAL_ESTATE">Real estate</Option>
                <Option value="RELIGION">Religion</Option>
                <Option value="RETAIL">Retail</Option>
                <Option value="JEWELRY">Jewelry</Option>
                <Option value="TECHNOLOGY">Technology</Option>
                <Option value="TELECOMMUNICATIONS">Telecommunications</Option>
                <Option value="TRANSPORTATION">Transportation</Option>
                <Option value="TRAVEL">Travel</Option>
                <Option value="ELECTRONICS">Electronics</Option>
                <Option value="NOT_FOR_PROFIT">Nonprofit</Option>
              </Select>
            </Form.Item>

            { props.type !== "low_standard" && <Form.Item
              label="Text messaging use case"
              name="usAppToPersonUsecase"
              rules={[
                {
                  required: true,
                  message: "Please select text messaging use case",
                },
              ]}
            >
              <Select
                value={formData?.usAppToPersonUsecase}
                onChange={(e) => handleChange("usAppToPersonUsecase", e)}
                placeholder="select app to person usecase"
                style={{ overflowY: "scroll", maxHeight: 250 }}
              >
                {createUsertoAppUseCase()}
              </Select>
            </Form.Item>
            }

            {/* Company Status (Private/Non-Profit) */}
            {/* company_type	
"private"
"non-profit" */}
            <Form.Item
              label="Company Status"
              name="companyType"
              rules={[
                {
                  required: true,
                  message: "Please choose your company status",
                },
              ]}
            >
              <Radio.Group
                value={formData?.company_type}
                onChange={(e) => {
                  handleChange("company_type", e.target.value);
                }}
              >
                <Radio.Button value="private">Private</Radio.Button>
                <Radio.Button value="non-profit">Non-Profit</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {/* Industry */}
            <Form.Item
              label="Entity type"
              name="business_type"
              rules={[
                {
                  required: true,
                  message: "Please choose your entity type",
                },
              ]}
            >
              <Select
                placeholder="Select Entity Type"
                value={formData?.business_type}
                onChange={(e) => handleChange("business_type", e)}
                style={{ overflowY: "scroll", maxHeight: 250 }}
              >
                <Option value="Partnership">Partnership</Option>
                <Option value="Co-operative">Co-operative</Option>
                <Option value="Limited Liability Corporation">
                  Limited Liability Corporation
                </Option>
                <Option value="Non-profit Corporation">
                  Non-profit Corporation
                </Option>
                <Option value="Corporation">Corporation</Option>
              </Select>
            </Form.Item>

            <Form.Item
              tooltip="This is a required field, for more info please see EIN Business Help "
              label="USA: Employer Identification Number (EIN)"
              name="businessRegistrationNumber"
              rules={[{ required: true }]}
            >
              <Input
                value={formData?.business_registration_number}
                onChange={(e) => {
                  handleChange("business_registration_number", e.target.value);
                }}
              />
            </Form.Item>
            {/* Website */}
            <Form.Item label="Website URL">
              <Input
                value={formData?.website_url}
                onChange={(e) => {
                  handleChange("website_url", e.target.value);
                }}
              />
            </Form.Item>

            {/* Regions of Operations */}
            <Form.Item
              tooltip="This is a required field "
              label="Regions of Operations"
              name="businessRegionsOfOperation"
              rules={[
                {
                  required: true,
                  message:
                    "Please choose a region in which your business operates.",
                },
              ]}
            >
              <Select
                placeholder="Select a region"
                value={formData?.business_regions_of_operation}
                onChange={(e) =>
                  handleChange("business_regions_of_operation", e)
                }
                style={{ overflowY: "scroll", maxHeight: 250 }}
              >
                <Option value="AFRICA">Africa</Option>
                <Option value="ASIA">Asia</Option>
                <Option value="EUROPE">Europe</Option>
                <Option value="LATIN_AMERICA">Latin America</Option>
                <Option value="USA_AND_CANADA">USA and Canada</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Description of text messaging intended use"
              name="campaignDescription"
              rules={[
                {
                  required: true,
                  message: "Please provide Description of intended use",
                },
                { min: 40, message: 'Description must be minimum 40 characters.' },
                { max: 4096, message: 'Description must be under 4096 characters.' },

              ]}
            >
              <Input.TextArea
                placeholder="Example: Send marketing messages about sales and offers"
                value={formData?.campaignDescription}
                onChange={(e) => {
                  handleChange("campaignDescription", e.target.value);
                }}
              ></Input.TextArea>
            </Form.Item>

            <Form.Item
              label="Text Message Samples"
              tooltip="This is a required field. Please provide examples of actual text messages that may be sent out. "
            >
              <Form.Item
                label="Message Samples"
                noStyle
                name="campaignMessageSample1"
                rules={[
                  {
                    required: true,
                    message: "Please provide First Campaign Message Sample",
                  },
                  {
                    max: 1024,
                    message: "Please keep the  First Campaign Message Sample under 1024 characters",
                  },
                ]}
              >
                <Input.TextArea
                placeholder="Hello John, this is Mike with XYZ Electric. We will be calling you shortly about your request for a job quote. Talk to you soon."
                  value={formData?.campaignMessageSample1}
                  onChange={(e) => {
                    handleChange("campaignMessageSample1", e.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>
              <br />
              <br />

              <Form.Item
                noStyle
                label="Campaign Message Samples"
                name="campaignMessageSample2"
                rules={[
                  {
                    required: true,
                    message: "Please provide Second Campaign Message Sample",
                  },
                  {
                    max: 1024,
                    message: "Please keep the  First Campaign Message Sample under 1024 characters",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Hi Sarah, this is Joan with Bob’s Real Estate Team. Here is the information you requested regarding selling your home in 30 days or less. Reply Stop to opt-out at any time."
                  value={formData?.campaignMessageSample2}
                  onChange={(e) => {
                    handleChange("campaignMessageSample2", e.target.value);
                  }}
                ></Input.TextArea>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Call to action/Opt-in (select all that apply)"
              valuePropName="messageFlow"
              name="messageFlow"
              rules={[
                {
                  required: true,
                  message: "Please Select a Call to action/Opt-in",
                }]}
            >
              <div style={{display: "flex", flexDirection: "column"}}>
              <Form.Item
              noStyle
              label="Call to action/Opt-in"
              valuePropName="messageFlow1"
              name="messageFlow1"
            >
                <Checkbox style={{ marginLeft: "8px" }}  onChange={(e) => {
                  handleChange("messageFlow1", e.target.checked);
                }}>
                  My contacts opt-in by providing their phone number to the
                  company and verbally agree to start recieving text messages.
                </Checkbox>
                </Form.Item>
                <Checkbox style={{ marginLeft: "8px" }} onChange={(e) => {
                  handleChange("messageFlow2", e.target.checked);
                }}>
                  My contacts fill out a form on a website or in-person and add
                  their phone number to start recieving text messages
                </Checkbox>
                <Checkbox style={{ marginLeft: "8px" }} onChange={(e) => {
                  handleChange("messageFlow3", e.target.checked);
                }}>
                  My contacts opt-in by sending a text message to one of our
                  business numbers.{" "}
                </Checkbox>
              </div>
            </Form.Item>
            {/* <Form.Item
              label=""
              valuePropName="checked"
            >
              <div style={{}}>
                <Checkbox style={{ marginLeft: "8px" }}>
                  Fix your funnel is twillio partner...
                </Checkbox>
                
              </div>
            </Form.Item> */}
            
            
          </div>

          <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
            <div>
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => refreshStart()}
              >
                Go Back to Selection
              </Button>
              <Button type="primary" htmlType="submit">
                submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title="Price Acknowledgement"
        open={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
        <br />
        <p>
          For more info about 10 DLC click{" "}
          <a target="_blank" href={helpDocs[props.platform]} rel="noreferrer">
            here.
          </a>
        </p>
      </Modal>

      <Modal
        title="Carrier Registration"
        centered
        open={helpModal}
        onOk={() => setHelpModal(false)}
        onCancel={() => setHelpModal(false)}
      >
       This form will ask some basic information about your business. Once registered, your company will be recognized as a trusted sender and allow for a higher volume of texting without being blocked by phone carrier spam filters. This will also prevent additional charges to your account for unregistered messages after January 1, 2023.
        Make sure to put a valid name and phone number for the authorized representitive of your company (Owner, Office Manager, etc).
      </Modal>
    </div>
  );
};

export default MainForm;

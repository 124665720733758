import React, { useState } from "react";
import {
  Select,
  Card,
} from "antd";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import "./MainForm.scss";


const { Option } = Select;


const platformDashboard = {
  "fyf": "https://login.fixyourfunnel.com/welcome/dashboard",
  "yeti": "https://app.yetitext.com/dashboard",
  "engage": "https://app.engagecrm.io/dashboard"
};


const Complete = (props) => {
  const { width, height } = useWindowSize();

  return (

    <div className="card-wrapper-confetti">
      <Confetti
        width={width}
        height={height} />
      <Card >
        <p><b>Thank you for filling out your information!</b></p>
        <p>Rest assured that your 10DLC approval Process is on its way! We will be in contact if anything goes wrong.</p>
        <p>10DLC helps businesses confidently send text messages to their customers individually or at scale with a local phone number, improving customer engagement and experience</p>
        <p>please enjoy, and let us know if there’s anything else we can help you with. </p>
        <p>Cheers!</p>
        <a target="_parent" href={platformDashboard[props.platform]}>Return To Dashboard</a>
      </Card>
    </div>
  );
};

export default Complete;
